<template>
    <div class="home-section-footer">
        <div class="footer-first-floor flex x-between">
            <div class="footer-aboutus-wrap">
                <h3 class="footer-h3-wrap">SPSSPRO</h3>
                <a
                    class="footer-sub-h3-wrap"
                    href="https://www.spsspro.com/introduction/"
                    target="_blank"
                    >产品介绍</a
                >
                <a class="footer-sub-h3-wrap" href="https://www.spsspro.com/help/" target="_blank"
                    >帮助中心</a
                >
                <a class="footer-sub-h3-wrap" href="/terms/" target="_blank">服务协议</a>
                <a class="footer-sub-h3-wrap" href="/privacy/" target="_blank">隐私政策</a>
                <a class="footer-sub-h3-wrap" href="https://www.spsspro.com/about/" target="_blank"
                    >关于我们</a
                >
            </div>
            <div class="footer-links-wrap">
                <h3 class="footer-h3-wrap">友情链接</h3>
                <a
                    class="footer-sub-h3-wrap"
                    href="https://www.wenjuan.com/?source=spsspro"
                    target="_blank"
                    >问卷网</a
                >
                <a
                    class="footer-sub-h3-wrap"
                    href="http://www.idiaoyan.com/?source=spsspro"
                    target="_blank"
                    >爱调研</a
                >
                <a
                    class="footer-sub-h3-wrap"
                    href="https://www.bestcem.com/?source=spsspro"
                    target="_blank"
                    >倍市得CEM</a
                >
            </div>
            <div class="footer-contactus-wrap">
                <h3 class="footer-h3-wrap">联系我们</h3>
                <div class="footer-sub-h3-wrap no-hover">
                    <span class="contactus-icon address"></span>
                    <span>上海市徐汇区宜山路700号B2幢楼22楼</span>
                </div>
                <div class="footer-sub-h3-wrap no-hover">
                    <span class="contactus-icon telephone"></span>
                    <span>400 670 0778</span>
                </div>
                <div class="footer-sub-h3-wrap no-hover">
                    <span class="contactus-icon email"></span>
                    <span>lin.peng@idiaoyan.com</span>
                </div>
                <div class="footer-sub-h3-wrap no-hover weixin">
                    <span class="contactus-icon wechat"></span>
                    <span class="wechat-text">微信联系</span>
                    <span class="contactus-icon wechat-qrcode">
                        <div class="concat-qrcode-hover">
                            <img
                                :src="require('@/assets/images/wu-enterprise-wechat.png')"
                                alt="SPSSPRO微信联系二维码"
                                width="72px"
                                height="72px"
                            />
                            <span class="triangle"></span>
                        </div>
                    </span>
                </div>
            </div>
            <div class="footer-contactus-wrap-mobile">
                <h3 class="footer-h3-wrap">联系我们</h3>
                <div class="footer-sub-h3-wrap">
                    <span class="h3-wrap-label">电话：</span>
                    <span>400 670 0778</span>
                </div>
                <div class="footer-sub-h3-wrap">
                    <span class="h3-wrap-label">邮箱：</span>
                    <span>lin.peng@idiaoyan.com</span>
                </div>
                <div class="footer-sub-h3-wrap">
                    <span class="h3-wrap-label">地址：</span>
                    <span>上海市徐汇区宜山路700号B2幢楼22楼</span>
                </div>
                <div class="footer-sub-h3-wrap footer-contact-mobile">
                    <div class="contact-item">
                        <wj-image
                            class=""
                            :src="require('@/assets/images/wu-enterprise-wechat.png')"
                            alt="联系人微信号"
                        ></wj-image>
                        <div>微信联系</div>
                    </div>
                    <div class="contact-item">
                        <wj-image
                            class=""
                            :src="require('@/assets/images/home-qr-code.png')"
                            alt="SPSSPRO微信公众号二维码"
                        ></wj-image>
                        <div>微信公众号</div>
                    </div>
                </div>
            </div>
            <div class="footer-followus-wrap">
                <h3 class="footer-h3-wrap">关注我们</h3>
                <div class="flex">
                    <div class="mr40">
                        <div class="footer-sub-h3-wrap no-hover">微信公众号</div>
                        <div class="wechart-qrcode">
                            <wj-image
                                class=""
                                :src="require('@/assets/images/home-qr-code.png')"
                                alt="SPSSPRO微信公众号二维码"
                            ></wj-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="border-wrap"></div>
        <div class="footer-second-floor">
            <span class="footer-second-text"
                >&copy;2013-2023 众言科技股份有限公司
                <a
                    href="https://beian.miit.gov.cn"
                    class="gov-link"
                    target="_blank"
                    rel="noopener nofollow"
                >
                    苏ICP备13021334号-22</a
                >
                <a
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002003723"
                    target="_blank"
                    rel="noopener nofollow"
                    class="flex x-center y-center"
                    ><img
                        :src="require('@/assets/images/beianicon.png')"
                        alt="spsspro备案图标"
                        style="width: 17px; height: 17px; margin-right: 3px"
                    />
                    <p style="display: inline-block">苏公网安备 32059002003723号</p></a
                >
            </span>
        </div>
    </div>
</template>

<script>
// import Vue from 'vue';
import { Image, Button } from '@wenjuan/ui';
// import Clipboard from 'clipboard';
// import 'vue2-toast/lib/toast.css';
// import Toast from 'vue2-toast';
// import { bbsUrlMinxin } from '@/mixin/js/getbbsUrl.js';
// Vue.use(Toast, { duration: 2000 });
export default {
    data() {
        return {};
    },
    // mixins: [bbsUrlMinxin],
    components: {
        'wj-image': Image,
        'wj-button': Button,
    },
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.home-section-footer {
    background-color: #2b323d;
    .footer-first-floor {
        width: 1170px;
        margin: 0 auto;
        height: 264px;
        @media (min-width: 641px) and (max-width: 1366px) {
            width: 960px;
        }
        @media (max-width: 640px) {
            width: auto;
            flex-direction: column;
            height: auto;
        }
        .footer-aboutus-wrap {
            width: 270px;
            height: 100%;
            text-align: left;
            @media (min-width: 641px) and(max-width: 1366px) {
                width: 194px;
            }
            @media (max-width: 640px) {
                width: calc(100% - 48px);
                margin: 0 auto 16px;
            }
        }
        .footer-links-wrap {
            width: 270px;
            height: 100%;
            text-align: left;
            @media (min-width: 641px) and (max-width: 1366px) {
                width: 204px;
            }
            @media (max-width: 640px) {
                width: calc(100% - 48px);
                margin: 0 auto 16px;
                border-top: 1px solid rgba(255, 255, 255, 0.05);
            }
        }
        .footer-contactus-wrap {
            width: 460px;
            height: 100%;
            text-align: left;
            @media (min-width: 641px) and (max-width: 1366px) {
                width: 380px;
            }
            @media (max-width: 640px) {
                display: none;
            }
        }
        .footer-contactus-wrap-mobile {
            display: none;
            width: calc(100% - 48px);
            margin: 0 auto;
            .footer-contact-mobile {
                justify-content: center;
                margin: 20px 0;
                line-height: 24px;
                ::v-deep img {
                    width: 72px;
                    height: 72px;
                }
                .contact-item {
                    text-align: center;
                    margin-left: 40px;
                    &:first-child {
                        margin-left: 0;
                    }
                    .bbs-img {
                        background-color: #fff;
                        width: 72px;
                        height: 72px;
                        margin-bottom: 5px;
                        img {
                            width: 66px;
                            height: 66px;
                            margin-top: 3px;
                        }
                    }
                }
            }
            .h3-wrap-label {
                flex-shrink: 0;
            }
            @media (max-width: 640px) {
                display: block;
                border-top: 1px solid rgba(255, 255, 255, 0.05);
            }
        }
        .footer-followus-wrap {
            flex-grow: 1;
            text-align: left;
            .wechart-qrcode {
                width: 72px;
                height: 72px;
                margin-top: 8px;
                background-color: #fff;
                text-align: center;
                img {
                    margin-top: 3px;
                }
            }
            @media (max-width: 640px) {
                display: none;
            }
        }
        // 公用
        .footer-h3-wrap {
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: left;
            color: #ffffff;
            line-height: 22px;
            margin: 60px 0 10px;
            @media (max-width: 640px) {
                margin-top: 20px;
                font-weight: 500;
            }
        }
        .footer-sub-h3-wrap {
            opacity: 0.5;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #ffffff;
            line-height: 28px;
            display: block;
            @media (max-width: 640px) {
                font-size: 13px;
                opacity: 1;
                color: #bbb;
                display: flex;
            }
            &:hover {
                cursor: pointer;
                opacity: 1;
            }
            &.no-hover {
                display: flex;
                &:hover {
                    cursor: text;
                    opacity: 0.5;
                }
            }
            &.weixin {
                opacity: 1;
                .wechat {
                    opacity: 0.5;
                }
                .wechat-text {
                    opacity: 0.5;
                }
                .wechat-qrcode {
                    opacity: 0.5;
                }
                &:hover {
                    opacity: 1;
                    .wechat {
                        opacity: 0.5;
                    }
                    .wechat-text {
                        opacity: 0.5;
                    }
                    .wechat-qrcode {
                        opacity: 1;
                    }
                }
            }
            .contactus-icon {
                width: 16px;
                height: 16px;
                background-size: cover;
                background-repeat: no-repeat;
                margin: auto 6px auto 0;
                &.address {
                    background-image: url(~@/assets/images/home-footer-address.png);
                }
                &.telephone {
                    background-image: url(~@/assets/images/home-footer-telephone.png);
                }
                &.email {
                    background-image: url(~@/assets/images/home-footer-email.png);
                }
                &.wechat {
                    background-image: url(~@/assets/images/home-footer-wechat.png);
                }
                &.wechat-qrcode {
                    background-image: url(~@/assets/images/home-footer-wechat-qrcode.png);
                    margin: auto 0 auto 6px;
                    cursor: pointer;
                    width: 14px;
                    height: 14px;
                    position: relative;
                    .concat-qrcode-hover {
                        display: none;
                        position: absolute;
                        width: 88px;
                        height: 88px;
                        text-align: center;
                        background-color: #fff;
                        border-radius: 2px;
                        left: 22px;
                        top: 0;
                        img {
                            margin: 8px;
                        }
                        .triangle {
                            width: 10px;
                            height: 10px;
                            background-color: #fff;
                            transform: rotate(45deg);
                            position: absolute;
                            top: 2px;
                            left: -2px;
                        }
                    }
                    &:hover {
                        background-image: url(~@/assets/images/home-footer-wechat-qrcode-hover.png);
                        .concat-qrcode-hover {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .border-wrap {
        width: 100%;
        height: 0px;
        opacity: 0.05;
        border-top: 1px solid #ffffff;
        // border-bottom: 1px solid #ffffff;
    }
    .footer-second-floor {
        width: 1170px;
        margin: 0 auto;
        height: 94px;
        @media (min-width: 641px) and (max-width: 1366px) {
            width: 960px;
        }
        @media (max-width: 640px) {
            width: auto;
            height: 134px;
            padding-bottom: env(safe-area-inset-bottom);
            padding-bottom: constant(safe-area-inset-bottom);
        }
        &.c_recommend {
            @media (max-width: 640px) {
                padding-bottom: 0;
                height: 94px;
            }
        }
        .footer-second-text {
            display: block;
            padding-top: 30px;
            opacity: 0.5;
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: #fff;
            line-height: 17px;
            text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5) inset;
            a {
                color: #fff;
            }
            @media (max-width: 640px) {
                padding-top: 20px;
                .gov-link {
                    display: block;
                }
            }
        }
    }
    @media (min-width: 641px) {
        .mobile-recommend {
            display: none;
        }
    }
    @media (max-width: 640px) {
        .mobile-recommend {
            line-height: 15px;
            position: fixed;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 40px;
            background: #e8f1ff;
            display: flex;
            align-items: center;
            justify-content: space-around;
            color: #1a78ff;
            font-size: 1.2rem;
            z-index: 100;

            padding-bottom: env(safe-area-inset-bottom);
            padding-bottom: constant(safe-area-inset-bottom);

            button {
                padding: 4px 12px;
                margin-right: 8px;
            }
            i {
                font-size: 16px;
            }
        }
    }
}
</style>
