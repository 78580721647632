<template>
    <div class="nav-wrap">
        <div class="course-nav-wrap flex x-between y-center">
            <div class="course-nav-left flex y-center" @click="handleClickToJumpMain">
                <wj-image
                    class="logo-wrap"
                    :src="require('@/assets/images/logo.png')"
                    alt="SPSSPRO，让数据分析更简单，问卷调查类数据分析不再愁"
                ></wj-image>
                <div class="logo-txt">课堂</div>
            </div>

            <!-- 已登录 -->
            <template v-if="userInfo && userInfo.username">
                <template v-if="isMobile">
                    <van-popover v-model="showPopover" trigger="click" placement="bottom-end">
                        <wj-image
                            class="popover-avatar"
                            :src="require('@/assets/images/avatar.png')"
                        ></wj-image>
                        <p class="popover-text" @click="clickMobileLogout">退出登录</p>
                        <template #reference>
                            <div class="course-nav-user flex y-center">
                                <div class="course-nav-user-name f16">
                                    {{ isAdmin ? userInfo.username : userInfo.school }}
                                </div>
                                <wj-image
                                    class="course-nav-user-avatar"
                                    :src="require('@/assets/images/avatar.png')"
                                ></wj-image>
                            </div>
                        </template>
                    </van-popover>
                </template>
                <template v-else>
                    <wj-dropdown
                        v-if="userInfo && userInfo.username"
                        @command="handleSelectAvatarMenu"
                    >
                        <div class="course-nav-user flex y-center">
                            <div class="course-nav-user-name f16 mr16">
                                {{ isAdmin ? userInfo.username : userInfo.school }}
                            </div>
                            <wj-image
                                class="course-nav-user-avatar"
                                :src="require('@/assets/images/avatar.png')"
                            ></wj-image>
                        </div>
                        <wj-dropdown-menu slot="dropdown">
                            <template v-if="isAdmin">
                                <wj-dropdown-item command="user">用户中心</wj-dropdown-item>
                                <wj-dropdown-item command="course">课程管理</wj-dropdown-item>
                            </template>
                            <wj-dropdown-item command="logout" class="dropdown-logout"
                                >退出登录</wj-dropdown-item
                            >
                        </wj-dropdown-menu>
                    </wj-dropdown>
                </template>
            </template>

            <!-- 未登录 -->
            <wj-button v-else :type="isMobile ? 'text' : 'primary'" @click="login">登录</wj-button>
        </div>
        <Login v-model="showDialog" :isMobile="isMobile"></Login>

        <!-- 移动端-退出登录弹框 -->
        <wj-dialog
            :visible.sync="showLogoutM"
            width="327px"
            append-to-body
            title="退出登录"
            type="small-center"
        >
            <span class="dialog-content-m">确定退出登录吗？</span>
            <span slot="footer" class="dialog-footer">
                <wj-button @click="showLogoutM = false">取 消</wj-button>
                <wj-button type="primary" @click="confirmLogoutM">确 定</wj-button>
            </span>
        </wj-dialog>
    </div>
</template>

<script>
import {
    Form,
    FormItem,
    Input,
    Button,
    Dialog,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Image,
} from '@wenjuan/ui';
import Login from '@/components/login/index.vue';
import { httpLogout } from '@/http/admin/index.js';
import { remove as removeStorage } from '@/assets/js/utils/storage.js';
import { Popover } from 'vant';

export default {
    components: {
        'wj-form': Form,
        'wj-form-item': FormItem,
        'wj-input': Input,
        'wj-button': Button,
        'wj-image': Image,
        'wj-dialog': Dialog,
        'wj-dropdown': Dropdown,
        'wj-dropdown-menu': DropdownMenu,
        'wj-dropdown-item': DropdownItem,
        Login,
        'van-popover': Popover,
    },
    data() {
        return {
            showDialog: false,
            userInfo: this.$store.state.userInfo,
            isMobile: false,
            showLogoutM: false,
            showPopover: false, // 移动端-头部弹出
        };
    },
    computed: {
        isAdmin() {
            return this.$store.state.userType === 'ADMIN';
        },
    },
    watch: {
        '$store.state.userInfo'(val) {
            this.userInfo = val;
        },
    },
    created() {
        if (
            navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
        ) {
            this.isMobile = true;
        }
    },
    methods: {
        // 登录
        login() {
            this.showDialog = true;
        },
        // 选择头向下拉框
        handleSelectAvatarMenu(command) {
            if (command === 'user') {
                this.$router.push('/user');
            } else if (command === 'course') {
                this.$router.push('/admin/course');
            } else if (command === 'logout') {
                if (this.isMobile) {
                    this.showLogoutM = true;
                } else {
                    this.$confirm('确定退出登录吗？', '退出登录', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    })
                        .then(() => {
                            httpLogout().then(() => {
                                removeStorage('isLogin');
                                this.$store.commit('SET_USERINFO', {});
                                this.$store.commit('SET_USERTYPE', '');
                                this.$router.push('/');
                                this.$message({
                                    type: 'success',
                                    message: '退出登录！',
                                    duration: 2000,
                                });
                            });
                        })
                        .catch(() => {});
                }
            }
        },

        // 跳转到主页
        handleClickToJumpMain() {
            this.$router.push('/');
        },
        // 移动端-点击退出登录
        clickMobileLogout() {
            this.showPopover = false;
            this.showLogoutM = true;
        },
        // 移动端-退出登录确定
        confirmLogoutM() {
            httpLogout().then(() => {
                this.showLogoutM = false;
                removeStorage('isLogin');
                this.$store.commit('SET_USERINFO', {});
                this.$store.commit('SET_USERTYPE', '');
                this.$router.push('/');
                this.$message({
                    type: 'success',
                    message: '退出登录！',
                    duration: 2000,
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-wrap {
    width: 100%;
    height: 68px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 100;
    @media (max-width: 640px) {
        height: 60px;
    }
    .course-nav-wrap {
        width: 1330px;
        height: 68px;
        margin: 0 auto;
        color: #3c3c3c;
        font-family: PingFangSC;
        @media (max-width: 640px) {
            width: 100%;
            height: 60px;
            padding: 0 24px;
            box-sizing: border-box;
        }
        .course-nav-left {
            cursor: pointer;
        }
        .logo-wrap {
            width: 147px;
            height: 24px;
            @media (max-width: 640px) {
                width: 94px;
                height: 15px;
            }
        }
        .logo-txt {
            font-size: 20px;
            margin-left: 12px;
            @media (max-width: 640px) {
                font-size: 16px;
                margin-left: 8px;
            }
        }
        .wj-button--text {
            font-size: 16px;
        }
        .course-nav-user {
            color: #3c3c3c;
            .course-nav-user-name {
                margin-right: 16px;
                @media (max-width: 640px) {
                    margin-right: 10px;
                }
            }
            .course-nav-user-avatar {
                width: 36px;
                height: 36px;
                border-radius: 18px;
                cursor: pointer;
            }
        }
    }
}

.wj-dropdown-menu.wj-popper .dropdown-logout {
    color: #e84955;
}
.wj-dropdown-menu.drop-menu-m {
    text-align: center;
    margin: 10px 0 0;
    padding: 16px 0 0;
    .dropdown-logout {
        background: #f5f7fa;
        line-height: 44px;
    }
}

.wj-dialog__body {
    .dialog-content-m {
        margin-top: 30px;
        margin-bottom: 10px;
        display: inline-block;
        color: #707070;
        font-size: 14px;
    }
}
.popover-avatar {
    width: 56px;
    height: 56px;
    border-radius: 18px;
    margin-bottom: 8px;
}
.popover-text {
    color: #e84955;
    background: #f5f7fa;
    line-height: 44px;
    margin: 0;
    font-size: 14px;
}
</style>

<style lang="scss">
.van-popup {
    .van-popover__content {
        width: 108px;
        background: #ffffff;
        text-align: center;
        padding-top: 16px;
        border-radius: 0;
    }
}
</style>
