<template>
    <div>
        <wj-dialog
            :visible.sync="showDialog"
            :width="isMobile ? '327px' : '770px'"
            custom-class="login-dialog"
            :show-close="false"
            append-to-body
            :close-on-click-modal="false"
        >
            <div class="dialog-wrap">
                <div v-if="!isMobile" class="left-pic"></div>
                <div class="right-login">
                    <i class="wj-icon-close login-close-icon" @click="handleCloseLoginDialog"></i>
                    <div class="login-head">
                        <div class="login-head-first">欢迎使用</div>
                        <div class="login-head-second">SPSSPRO课程管理系统</div>
                    </div>
                    <div class="login-main">
                        <wj-form :model="formData" :rules="rules" ref="ruleForm">
                            <wj-form-item prop="username">
                                <wj-input v-model="formData.username" placeholder="请输入用户名称">
                                    <i
                                        v-if="!isMobile"
                                        slot="prefix"
                                        class="wj-input__icon wj-icon-user"
                                    ></i
                                ></wj-input>
                            </wj-form-item>
                            <wj-form-item prop="password">
                                <wj-input
                                    v-model="formData.password"
                                    placeholder="请输入登录密码"
                                    type="password"
                                    @keydown.enter.native="doLogin"
                                >
                                    <i
                                        v-if="!isMobile"
                                        slot="prefix"
                                        class="wj-input__icon wj-icon-lock"
                                    ></i
                                ></wj-input>
                            </wj-form-item>
                            <wj-button type="primary" @click="doLogin">登录</wj-button>
                        </wj-form>
                    </div>
                    <div class="login-extra">
                        登录即同意
                        <router-link target="_blank" class="link" :to="{ path: '/terms' }"
                            >用户协议</router-link
                        >和<router-link target="_blank" class="link" :to="{ path: '/privacy' }"
                            >隐私协议</router-link
                        >
                    </div>
                </div>
            </div>
        </wj-dialog>
    </div>
</template>
<script>
import {
    Form,
    FormItem,
    Input,
    Button,
    Dialog,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Image,
} from '@wenjuan/ui';
import { httpPostAdminLogin } from '@/http/admin/index.js';
import { set as setStorage } from '@/assets/js/utils/storage.js';
export default {
    components: {
        'wj-form': Form,
        'wj-form-item': FormItem,
        'wj-input': Input,
        'wj-button': Button,
        'wj-image': Image,
        'wj-dialog': Dialog,
        'wj-dropdown': Dropdown,
        'wj-dropdown-menu': DropdownMenu,
        'wj-dropdown-item': DropdownItem,
    },
    data() {
        return {
            formData: {
                username: '',
                password: '',
            },
            rules: {
                username: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
                password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
            },
        };
    },
    props: {
        showDialog: {
            type: Boolean,
            default: false,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    model: {
        prop: 'showDialog',
        event: 'fn',
    },
    methods: {
        handleCloseLoginDialog() {
            this.$emit('fn', false);
        },
        doLogin() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    let { username, password } = this.formData;
                    let params = {
                        username,
                        password,
                    };
                    httpPostAdminLogin(params)
                        .then((res) => {
                            if (res.data.code == 'OK') {
                                setStorage('isLogin', true);
                                this.$emit('fn', false);
                                this.$store.dispatch('getUserInfo');
                                this.$message({
                                    type: 'success',
                                    message: '登录成功！',
                                    duration: 2000,
                                });
                            }
                        })
                        .catch(() => {});
                }
            });
        },
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/css/theme-variable.scss';
// 登陆模态框
.dialog-wrap {
    box-sizing: border-box;
    display: flex;
    border-radius: 4px;
    .left-pic {
        width: 350px;
        height: 480px;
        // background-color: #6cf;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(~@/assets/images/home-login.png);
    }
    .right-login {
        width: 420px;
        height: 480px;
        @media (max-width: 640px) {
            width: 327px;
            height: auto;
            padding-bottom: 21px;
        }
        > div {
            padding: 0 60px;
            @media (max-width: 640px) {
                padding: 0 24px;
            }
        }
        .login-close-icon {
            margin: 30px 30px 14px 374px;
            font-size: 16px;
            cursor: pointer;
            @media (max-width: 640px) {
                margin: 18px 24px 14px 283px;
                font-size: 20px;
            }
        }
        .login-head {
            font-family: PingFangSC, PingFangSC-Regular;
            color: #3c3c3c;
            .login-head-first {
                font-size: 36px;
                font-weight: 400;
                @media (max-width: 640px) {
                    font-size: 24px;
                    line-height: 34px;
                }
            }
            .login-head-second {
                font-size: 28px;
                font-weight: bold;
                @media (max-width: 640px) {
                    font-size: 24px;
                    line-height: 34px;
                }
            }
        }
        .login-main {
            background-color: #fff;
            box-sizing: border-box;
            margin: 40px 0;
            @media (max-width: 640px) {
                margin: 30px 0 20px;
            }
            .wj-form-item {
                margin-bottom: 18px;
                @media (max-width: 640px) {
                    margin-bottom: 20px;
                }
            }
            .wj-input {
                height: 42px;
                @media (max-width: 640px) {
                    height: 40px;
                }
                ::v-deep input {
                    height: 42px;
                    line-height: 42px;
                    @media (max-width: 640px) {
                        height: 40px;
                        line-height: 40px;
                    }
                }
                ::v-deep .wj-input__prefix {
                    i {
                        font-size: 16px;
                    }
                }
            }
            .wj-button {
                width: 100%;
                height: 42px;
                margin-top: 12px;
                @media (max-width: 640px) {
                    height: 40px;
                    margin-top: 0;
                }
            }
        }
        .login-extra {
            text-align: center;
            color: $--color-font-3;
            .link {
                margin: 0 5px;
                color: $--color-font-2;
            }
        }
    }
}
::v-deep .wj-dialog__body {
    margin: 0;
    padding: 0;
}
::v-deep .wj-dialog__header {
    display: none;
}
</style>
